import React from 'react'

const Loading = () => {
  return (
    <div>
         <div className="loading-wrapper">
            <span className="loading-dot"></span>
            <div className="loading-dots">
                <span></span>
                <span></span>
                <span></span>
            </div>
            </div>
    </div>
  )
}

export default Loading